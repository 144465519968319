import { Divider, Radio, Select, Space, message } from "antd";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { CampaignContext } from "../../../contexts/campaignContext";
import { TargetingContext } from "../../../contexts/targetingContext";
import { SpecificCopyPaste } from "../components/SpecificCopyPaste";
import styles from "./AdditionalTargeting.module.css";
import { ListSelect } from "../components/ListSelect";

const { Option } = Select;

export const AdditionalTargeting = () => {
  const { manufacturerAll, ipListAll } = useContext(TargetingContext);
  const { editId, targeting, dispatchTargeting } = useContext(CampaignContext);

  const [selectedIPAll, setSelectedIPAll] = useState(true);
  const [selectedDevManufAll, setSelectedDevManufAll] = useState(true);

  const handleManufacturerUpdate = (manufacturerIds) => {
    const items = manufacturerIds.map((item) => {
      const existingManufacturer = targeting.device_manufacturers.items.find(
        (manufacturer) => manufacturer === item
      );
      return existingManufacturer || item;
    });
    dispatchTargeting({
      type: "device_manufacturers",
      payload: { items, op: "in" },
    });
  };

  const handleTargetingUpdate = (value) => {
    const payload = {
      ...value,
      items: value.items,
    };
    dispatchTargeting({ type: "ip", payload });
  };

  const handleListTargetingUpdate = (lists) => {
    const payload = {
      ...targeting.ip,
      lists,
    };
    dispatchTargeting({ type: "ip", payload });
  };

  const blacklistId = useMemo(() => {
    const blacklist = ipListAll.find((item) => item.is_global);
    return blacklist?.id;
  }, [ipListAll]);

  useEffect(() => {
    if (targeting.ip.items.length || targeting.ip.lists.length) {
      setSelectedIPAll(false);
    }
    targeting.device_manufacturers.items.length && setSelectedDevManufAll(false);
  }, [targeting.ip, targeting.device_manufacturers, blacklistId]);

  useEffect(() => {
    !editId &&
      blacklistId &&
      !JSON.parse(localStorage.getItem("campaign_targeting")) &&
      dispatchTargeting({
        type: "ip",
        payload: { op: "nin", items: [], lists: [blacklistId] },
      });
  }, [editId, blacklistId, dispatchTargeting]);

  return (
    <div>
      <div>
        <p className={styles.additionalTargetingSubtitle}>IPs &amp; ISPs</p>
        <div className={styles.radioButtonSelection}>
          <Radio.Group
            value={selectedIPAll}
            onChange={(e) => {
              if (e.target.value) {
                dispatchTargeting({
                  type: "ip",
                  payload: { op: "in", items: [], lists: [] },
                });
              }
              setSelectedIPAll(e.target.value);
            }}
          >
            <Space direction="vertical">
              <Radio value={true}>Target all IPs & ISPs</Radio>
              <Radio value={false}>Target specific IPs & ISPs</Radio>
            </Space>
          </Radio.Group>
        </div>
        {!selectedIPAll && (
          <>
            <div className={styles.opTargeting}>
              <span>I want to </span>
              <Select
                value={targeting.ip.op}
                onChange={(op) => {
                  if (op === "in" && targeting.ip.lists.includes(blacklistId)) {
                    message.warning(
                      "Global blacklist removed, including blacklist is not allowed."
                    );
                    handleListTargetingUpdate(
                      targeting.ip.lists.filter((item) => item !== blacklistId)
                    );
                  }
                  handleTargetingUpdate({ op, items: targeting.ip?.items });
                }}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Option value="in">Include</Option>
                <Option value="nin">Exclude</Option>
              </Select>
              the following IPs in targeting:
            </div>
            <ListSelect
              type="ip"
              op={targeting.ip.op}
              value={targeting.ip.lists}
              onChange={(value) => handleListTargetingUpdate(value)}
            />
            <SpecificCopyPaste
              type="ip"
              value={targeting.ip}
              onChange={(value) => handleTargetingUpdate(value)}
            />
          </>
        )}
      </div>
      <Divider />
      <div>
        <p className={styles.additionalTargetingSubtitle}>Device Manufacturers</p>
        <div className={styles.radioButtonSelection}>
          <Radio.Group
            value={selectedDevManufAll}
            onChange={(e) => {
              if (e.target.value) {
                dispatchTargeting({
                  type: "device_manufacturers",
                  payload: { op: "in", items: [] },
                });
              }
              setSelectedDevManufAll(e.target.value);
            }}
          >
            <Space direction="vertical">
              <Radio value={true}>Target all device manufacturers</Radio>
              <Radio value={false}>Target specific device manufacturers</Radio>
            </Space>
          </Radio.Group>
          {!selectedDevManufAll && (
            <div className={styles.specificItemSelect}>
              <Select
                mode="multiple"
                value={targeting.device_manufacturers.items}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={handleManufacturerUpdate}
                showSearch
                style={{ width: "50%" }}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                maxTagCount="responsive"
                placeholder="Select Device Manufacturers"
              >
                {manufacturerAll.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </div>
          )}
        </div>
      </div>
      <Divider />
      <div>
        <p className={styles.additionalTargetingSubtitle}>Traffic Types</p>
        <div className={styles.radioButtonSelection}>
          <Radio.Group
            value={targeting.traffic_type}
            onChange={(e) => {
              if (e.target.value) {
                dispatchTargeting({
                  type: "traffic_type",
                  payload: e.target.value,
                });
              }
            }}
          >
            <Space direction="vertical">
              <Radio value={"Both"}>Target all traffic types</Radio>
              <Radio value={"Mainstream"}>Target mainstream traffic</Radio>
              <Radio value={"Adult"}>Target adult traffic</Radio>
            </Space>
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};
